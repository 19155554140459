@use "@angular/material" as mat;
@import "theme";
@import "mixins";

@property --loadingLight {
  syntax: '<color>';
  initial-value: white;
  inherits: false;
}

@property --loadingDark {
  syntax: '<color>';
  initial-value: mat.get-color-from-palette($my-primary, 50);
  inherits: false;
}

:root {
  --table-top-position: #{calc($nav-footer-size + 2 * $nav-container-padding-vertical + 2 * $container-margin + 2 * 2px)};
}

html, body {
  min-height: 100vh;
  overscroll-behavior-x: contain;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: linear-gradient(to top, mat.get-color-from-palette($my-primary, 50) 0%, white 100%);
}

@media print {
  body{
    background: none;
  }

  canvas {
    min-width: 100%;
    max-width: 100%;
    width: auto !important;
  }
}

body > * {
  flex-grow: 1;
}

main {
  display: inline-block;
  flex-grow: 1;
  width: 100%;
  padding-top: 24px;
  background-color: white;
}

.container {
  margin: $container-margin 40px;
}

@include md-breakpoint {

  .container {
    margin: $container-margin 32px;
  }
}

@include sm-breakpoint {

  .container {
    margin: $container-margin 24px;
  }
}

@include xs-breakpoint {

  .container {
    margin: $container-margin 16px;
  }
}

.menu-content {
  padding: 12px 24px;

  span.menu-title {
    display: block;
    font-weight: bold;
    margin-bottom: 4px;
  }

  hr {
    margin: 20px 0;
    background-color: mat.get-color-from-palette($my-primary, 100);
    border: none;
    height: 1px;
  }

  button, section {
    margin-top: 12px;
  }

  .mat-checkbox-label {
    white-space: normal;
    padding-left: 4px;
    line-height: 20px;
  }
}

button {
  width: 100%;

  .mdc-button__label {
    letter-spacing: 0.04em;
    white-space: nowrap;
    font-size: 0.9em;
  }
}

hr {
  margin: 32px 0;
}

p.small {
  margin: 12px;
  text-align: center;
  font-size: 0.9em;
}

a {
  color: mat.get-color-from-palette($my-primary, 700);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  background: mat.get-color-from-palette($my-primary, 500) !important;
  color: white !important;
}

.loading-component {
  transition-delay: .5s;
  transition-duration: 1s;
  transition-property: --loadingLight, --loadingDark, filter, border;
}

.loading {
  @extend .loading-component;
  background: linear-gradient(100deg, var(--loadingLight) 30%, var(--loadingDark) 50%, var(--loadingLight) 70%);
  background-size: 400%;
  filter: blur(1.5px);
  border-radius: 6px;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
