@import "variables";

/* Extra small devices */

@mixin xs-breakpoint {
  @media (max-width: #{$breakpoint-xs - 1px}) {
    @content;
  }
}

/* Small devices */

@mixin sm-breakpoint {
  @media (min-width: #{$breakpoint-xs}) and (max-width: #{$breakpoint-sm - 1px}) {
    @content;
  }
}

@mixin lt-sm-breakpoint {
  @media (max-width: #{$breakpoint-sm - 1px}) {
    @content;
  }
}

@mixin gt-sm-breakpoint {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

/* Medium devices */

@mixin md-breakpoint {
  @media (min-width: #{$breakpoint-sm}) and (max-width: #{$breakpoint-md - 1px}) {
    @content;
  }
}

@mixin lt-md-breakpoint {
  @media (max-width: #{$breakpoint-md - 1px}) {
    @content;
  }
}

@mixin gt-md-breakpoint {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

/* Large devices */

@mixin lg-breakpoint {
  @media (min-width: #{$breakpoint-md}) and (max-width: #{$breakpoint-lg - 1px}) {
    @content;
  }
}

@mixin lt-lg-breakpoint {
  @media (max-width: #{$breakpoint-lg - 1px}) {
    @content;
  }
}

@mixin gt-lg-breakpoint {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

/* Extra large devices */

@mixin xl-breakpoint {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin lt-xl-breakpoint {
  @media (max-width: #{$breakpoint-lg - 1px}) {
    @content;
  }
}

@mixin gt-xl-breakpoint {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

